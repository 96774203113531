<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h1>{{ `${ $t('Chain') } ${ $t('Activity') }` }}</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-right">
            <v-data-table
              :headers="headers"
              :items="listData"
              :loading="loading"
              :headers-length="9"
              :header-props="{ sortIcon: 'mdi-chevron-up' }"
              :loader-height="1"
              no-data-text="No Record Found."
              item-key="bcm_coin_id"
              hide-default-footer
              disable-pagination
              mobile-breakpoint="0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-center currency-details">
                    <span class="ml-2">{{ item.calc_rank }}</span>
                  </td>
                  <td
                    class="text-left currency-details secondary--text"
                    @click="onCurrencyActivityView(item)"
                  >
                    <div class="d-flex pt-2">
                      <div>
                        <img
                          v-if="item.logo"
                          alt=""
                          :src="item.logo"
                          :lazy-src="item.logo"
                          class="currency-logo"
                        />
                      </div>
                      <div class="ml-2">
                        <span>{{ item.chain }}</span>
                      </div>
                    </div>
                  </td>
                  <td
                    class="text-left adjust-center currency-details grey--text"
                    @click="onCurrencyActivityView(item)"
                  >
                    <div class="d-flex">
                      <div class="ml-5">
                        <span>{{ item.symbol }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-right">
                    <span>{{ helper.convertToBigNumber(item.trans_count,0) }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ helper.convertToBigNumber(item.trans_fee,2) }}</span>
                  </td>
                  <td class="text-right pa-0">
                    <template v-if="!chartLoading">
                      <LineChart :params="{ label: item.label, data: item.data }" />
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CURRENCY_ACTIVITY_REQUEST, CURRENCY_ACTIVITY_DETAILS_REQUEST } from '@/store/overview/action'
import helper from '@/utils/helper'
import { get, commit, call } from 'vuex-pathify'
import LineChart from '@/components/organisms/chart/Chain'

export default {
  name:'ChainActivity',
  components: {
    LineChart,
  },
  data: () => ({
    helper: helper,
    loading: true,
    chartLoading: true,
    listData: [],
    days: '1D',
    headers: [
      {
        text: 'Rank',
        value: 'calc_rank',
        align: 'center',
        width: '8%',
        sortable: true,
        fixed: true,
      },
      {
        text: 'Chain',
        value: 'chain',
        align: 'left',
        width: '12%',
        sortable: true,
        fixed: true,
      },
      {
        text: '',
        value: 'Symbol',
        align: 'left',
        width: '3%',
        sortable: true,
        fixed: true,
      },
      {
        text: '24h Transactions',
        value: 'trans_count',
        align: 'right',
        width: '15%',
        sortable: true,
      },
      {
        text: 'Transaction Fee',
        value: 'trans_fee',
        align: 'right',
        width: '15%',
        sortable: true,
      },
      {
        text: '',
        value: '',
        align: 'center',
        width: '12%',
        sortable: false,
      },
    ],
  }),
  watch: {
    'listData': {
      handler: function () {
        this.chartLoading = this.listData.every((x) => !x.loading) ? false : true
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeMount() {
    await this.onFetchCurrencyActivity()
  },
  methods: {
    ...call('overview',['CURRENCY_ACTIVITY_REQUEST', 'CURRENCY_ACTIVITY_DETAILS_REQUEST']),
    onCurrencyActivityView(item) {
      this.$router.push({
        name: 'ChainActivityDetails',
        path: `/chain-activity/details/${item.bcm_coin_id}`,
        params: {
          name: item.bcm_coin_id,
          id: item.bcm_coin_id,
        },
      })
    },
    async onFetchCurrencyActivity() {
      this.loading = true
      const payload = {
        days: this.days,
        sort: 'asc',
      }
      const temp = await this.CURRENCY_ACTIVITY_REQUEST(payload)

      this.listData = JSON.parse(JSON.stringify(temp))
      this.listData = this.listData.sort((a, b) => (a.calc_rank > b.calc_rank) ? 1 : -1)
      this.loading = false
      this.listData.forEach((o, idx) => {
        this.listData[idx]['label'] = []
        this.listData[idx]['data'] = []
        this.listData[idx]['loading'] = true,
        this.listData[idx]['sortDirection'] = 'asc'
      })

      this.chartLoading = this.listData.every((x) => !x.loading) ? false : true
      for await (const [i, item] of this.listData.entries()) {
        const payload = {
          days: this.days,
          sort: 'asc',
          id: item.bcm_coin_id,
          setData: true,
        }

        const res = await this.CURRENCY_ACTIVITY_DETAILS_REQUEST(payload)

        this.$set(this.listData, i, {
          ...this.listData[i],
          'loading': false,
          'label': res?.label,
          'data': res?.data,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.mod-height {
  height: 50px!important;
}

.currency-details {
  &:hover {
    cursor: pointer;
  }
}

.currency-logo {
  height: 25px;
}
</style>
