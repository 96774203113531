<script>
import Chart from 'chart.js'
import { Line, mixins, generateChart } from 'vue-chartjs'
import helper from '@/utils/helper'
import _ from 'lodash'
import constants from '@/constants/constants'

Chart.defaults.LineWithLine = Chart.defaults.line
Chart.defaults.global.hover.intersect = false
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease)

  },
})

const CustomLine = generateChart('chain-chart', 'LineWithLine')

export default {
  name:'Chart',
  extends: CustomLine,
  mixins: mixins.reactiveProp,
  props: {
    'params': { type: Object,default: () => {} },
  },
  data: () => ({
    helper: helper,
  }),
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        animation: {
          duration: 0.5,
        },
        scales: {
          xAxes: [{
            ticks: { display: false },
            gridLines: { display: false },
          }],
          yAxes: [{
            ticks: { display: false },
            gridLines: { display: false },
          }],
        },
        tooltips: {
          enabled: false,
          callbacks: {
          },
        },
        elements: {
          line: { tension: 0.4 },
        },
        height: 60,
      }
    },
  },
  watch: {
    '$store.state.overview.chartData': {
      handler: function (value) {
        this.createChart()
      },
      deep: true,
    },
    '$vuetify.theme.dark': {
      handler: function (value) {
        this.$vuetify.theme.dark = value
        this.createChart()
      },
      deep: true,
    },
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {

      this.renderChart(
        {
          labels: this.params.label,
          datasets: [
            {
              data: this.params.data,
              backgroundColor: 'transparent',
              borderColor: constants.chartAxisColors.blue,
              borderWidth: 1,
              fill: false,
              pointRadius: 0,
              pointHitRadius: 0,
              pointHoverRadius: 0,
            },
          ],
        },
        this.options,
      )

    },
  },
}
</script>

<style lang="scss" scoped>
canvas {
  display: inline;
  background: none !important;
}
.title {
    display: none;
}

canvas {
  height: 60px!important;
  width: 100%!important;
  padding: 0 3px;
}
</style>
